
const l_en = {
    'title_1': '1. Get the Compendium app',
    'description_1': 'Download the Compendium app from the App Store or Google Play.',
    'title_2': '2. Create an account in the app',
    'description_2': 'Make sure you have an account in the app. Open the menu in the top right and select \'Log in\'.Create your account before you continue here. If you already have an account, you can skip this step.',
    'title_3': '3. Log in below',
    'description_3': 'Make sure you use the same account as you have just created in the app. Otherwise we won\'t know how to give you the subscription!',
    'logged_in_as': 'Great! You are logged in as',
    'sign_in_as_different_user': 'Sign in as a different user',
    'title_4': '4. Enter your promo code',
    'congrats': 'Congrats! You have claimed this promo code. Enjoy 6 months of learning! You can now close this window.',
    'already_claimed': "This account already has claimed a gift card.",
    'no_user_found': 'Could not access this account. Please make sure you finished setting up your account in the app first.',

    // '': '',
    'promocode': 'Promo code',
    'redeem': 'Redeem code',
    'email': 'Email',
    'password': 'Password',
    'sign_in': 'Sign in',
    'invalid_credentials': 'Unable to log in with these credentials. Please try again.',

};

const l_nl = {
    'title_1': '1. Download de Compendium-app',
    'description_1': 'Download de Compendium-app van de App Store of Google Play.',
    'title_2': '2. Maak een account aan in de app',
    'description_2': 'Maak een account aan in de app. Open het menu in de hoek en kies \'Log in\'. Maak een account voordat je verder gaat. Als je al een account hebt, kun je deze stap overslaan.',
    'title_3': '3. Log in hieronder',
    'description_3': 'Log in met dezelfde account als je hebt gemaakt in de app. Anders weten we niet hoe we het abonnement aan je moeten geven!',
    'logged_in_as': 'Top! Je bent ingelogd als',
    'sign_in_as_different_user': 'Inloggen als een andere gebruiker',
    'title_4': '4. Voer je kortingscode in',
    'congrats': 'Gefeliciteerd! Je hebt de kortingscode ingewisseld. Je kunt nu dit venster sluiten.',
    'already_claimed': 'Deze account heeft al een kortingscode geactiveerd.',
    'no_user_found': 'Kan deze account niet ophalen. Zorg dat je eerst je account in de app hebt gemaakt voordat je hier verder gaat.',

    // '': '',
    'promocode': 'Kortingscode',
    'redeem': 'Verzilveren',
    'email': 'E-mail',
    'password': 'Wachtwoord',
    'sign_in': 'Inloggen',
    'invalid_credentials': 'Kan niet inloggen met deze gegevens. Probeer het nog eens.',
}

function l(locale, key) {
    if (locale == 'nl') {
        return l_nl[key] || l_en[key];
    }
    return l_en[key];
}

export {
    l
};
