import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './firebaseConfig'
// import firebase from './firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        user: {
            loggedIn: false,
            data: null,
            sessionToken: null,
        }
    },
    getters: {
        user(state) {
            return state.user
        }
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        SET_LOGGED_OUT(state) {
            state.user.sessionToken = null;
            state.user.data = null;
            state.user.loggedIn = false;
        },
        SET_SESSION_TOKEN(state, token) {
            console.log(`SET_SESSION_TOKEN: ${token}`);
            state.user.sessionToken = token;
        }
    },
    actions: {
        async register(context, { email, password }) {
            //const response = await createUserWithEmailAndPassword(auth, email, password)
            try {
                // const auth = firebase.auth();
                const response = await createUserWithEmailAndPassword(auth, email, password);
                const idToken = await response.user.getIdToken();

                if (response) {
                    context.commit('SET_USER', response.user)
                    context.commit('SET_SESSION_TOKEN', idToken)
                    //response.user.updateProfile({ displayName: email })
                } else {
                    throw new Error('Unable to register user')
                }
            } catch (error) {
                console.error("Error creating new user:", error);
                throw error
            }
        },

        async logIn(context, { email, password }) {
            try {
                // const auth = firebase.auth();
                const response = await signInWithEmailAndPassword(auth, email, password)
                const idToken = await response.user.getIdToken();
                if (response && response.user) {
                    context.commit('SET_USER', response.user)
                    context.commit('SET_SESSION_TOKEN', idToken)
                    //response.user.updateProfile({ displayName: email })
                    return response.user;
                } else {
                    throw new Error('Login failed');
                }
            } catch (error) {
                console.error("Error logging in user:", error);
                throw error;
            }
        },
        async logOut(context) {
            // const auth = firebase.auth();
            await signOut(auth)
            context.commit('SET_USER', null)
            context.commit('SET_SESSION_TOKEN', null)
        },

        async fetchUser(context, user) {
            context.commit("SET_LOGGED_IN", user !== null);
            if (user) {
                context.commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email
                });
            } else {
                context.commit("SET_USER", null);
            }
        }
    },
});

export default store;
