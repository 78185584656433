<template>
    <div class="card">
        <div class="card-body">
            <form action="#" @submit.prevent="handleSignIn">
                <div class="form-group row">
                    <label for="email" class="col-md-4 col-form-label text-md-right">{{ l(this.params.lang, 'email')
                        }}</label>

                    <div class="col-md-6">
                        <input id="email" type="email" class="form-control" name="email" value required autofocus
                            v-model="email" />
                    </div>
                </div>

                <div class="form-group row">
                    <label for="password" class="col-md-4 col-form-label text-md-right">{{ l(this.params.lang,
                'password') }}</label>

                    <div class="col-md-6">
                        <input id="password" type="password" class="form-control" name="password" required
                            v-model="password" />
                    </div>
                </div>

                <div v-if="error" class="alert alert-danger">{{ error }}</div>

                <div class="form-group row mb-0">
                    <div class="col-md-8 offset-md-4">
                        <button type="submit" class="comp-purple">{{ l(this.params.lang, 'sign_in') }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import { l } from '@/repository/l.js';

export default {
    name: "UserLoginComponent",
    data() {
        return {
            email: '',
            password: '',
            error: null
        }
    },
    computed: {
        params: function () {
            const NL = window.location.search.substring(1) == 'lang=nl';
            return {
                lang: NL ? 'nl' : 'en',
            };
        }
    },
    methods: {
        l: l,
        handleSignOut() {
            const auth = getAuth();
            signOut(auth).then(() => {
                // Navigate back to login screen
                this.$router.push('/login');
            }).catch((error) => {
                console.log(error);
            });
        },
        async handleSignIn() {
            try {
                const user = await this.$store.dispatch('logIn', {
                    email: this.email,
                    password: this.password
                });
                // Handle successful login
                this.error = null;
                this.$emit('onLogin', user);
            } catch (err) {
                console.log(err.message);
                this.error = this.l(this.params.lang, 'invalid_credentials');
            }
            return false;
        }
    }
};
</script>
