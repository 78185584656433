import { getAuth } from 'firebase/auth';

// const BASE_URL = "https://compendium.thisispix.com/api";
const BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/api`;

function prefixImageUrl(imageUrl) {
    return `${process.env.VUE_APP_API_BASE_URL}${imageUrl}`;
}

async function fetchDisciplines(locale = 'nl') {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/disciplines?locale=${locale}&all=1`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching disciplines:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function fetchDetailedDisciplines(locale = 'nl') {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/disciplines/detailed?locale=${locale}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching disciplines:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function fetchUser() {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/user`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Something when wrong while reaching this account. Please try again.');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function claimCode(code) {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/vouchers/claim`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "code": code.trim().toUpperCase() })
        });
        if (!response.ok) {
            throw new Error('Unable to claim this code. Please check if you typed it correctly and try again.');
        }
        return await response.json();
    } catch (error) {
        console.error('Error claiming code:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function fetchUsers(offset, limit) {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/users?offset=${offset}&limit=${limit}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function downloadUsersCsv() {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    await fetch(`${BASE_URL}/users/csv`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        responseType: 'blob'
    }).then(response => {
        console.log(response);
        if (response.ok) {
            //let contentType = response.headers.get('content-type');
            return response.blob();
        }
    }).then((response) => {
        // create file link in browser's memory
        const blob = new Blob([response], { type: "text/csv" })
        //const href = URL.createObjectURL(response.data);
        const href = URL.createObjectURL(blob)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'compendium-users.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
}

async function fetchPromos() {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/promos`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching promos:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function fetchQuestions(locale = 'nl', disciplineId = null) {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const endpoint = disciplineId
            ? `${BASE_URL}/discipline/${disciplineId}/all_questions?locale=${locale}`
            : `${BASE_URL}/questions/all?locale=${locale}`;
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching questions:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function fetchQuestionsWithMedia(locale = 'nl') {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const endpoint = `${BASE_URL}/questions/media?locale=${locale}`;
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching questions:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function fetchAllMedia() {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const endpoint = `${BASE_URL}/media`;
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching questions:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function uploadCsv(formData) {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/questions/import`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching questions:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function runQualityChecks() {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/check`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error checking quality:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function uploadMedia(formData) {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/media`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        // if (!response.ok) {
        //     throw new Error('Network response was not ok');
        // }
        return await response.json();
    } catch (error) {
        console.error('Error fetching questions:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function uploadPromo(formData) {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/promos`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching questions:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function createBattle(battle) {
    try {
        console.log(`Creating battle: ${JSON.stringify(battle)}`);
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/battles`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(battle)
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error creating battle:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function fetchBattle(battleId) {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        // Pass a fixed locale. We don't care about the locale, but the backend needs one to fetch the questions
        const endpoint = `${BASE_URL}/battles/${battleId}?locale=en`;
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching battles:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function fetchBattles() {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const endpoint = `${BASE_URL}/battles/all?locale=en`;
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching battles:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function fetchBattleLeaderboard(battleId) {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const endpoint = `${BASE_URL}/battles/${battleId}/leaderboard`;
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error(`Error fetching leaderboard ${battleId} `, error);
        throw error; // Re-throw to handle it in the component
    }
}

async function startBattle(battleId) {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const endpoint = `${BASE_URL}/battles/${battleId}/start`;
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error(`Error starting battle ${battleId} `, error);
        throw error; // Re-throw to handle it in the component
    }
}

async function stopBattle(battleId) {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const endpoint = `${BASE_URL}/battles/${battleId}/stop`;
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error(`Error stopping battle ${battleId} `, error);
        throw error; // Re-throw to handle it in the component
    }
}

async function fetchVouchers() {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/vouchers`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching vouchers:', error);
        throw error; // Re-throw to handle it in the component
    }
}

async function createVouchers(amount, durationDays) {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${BASE_URL}/vouchers/create`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ amount: amount, duration: durationDays })
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error creating vouchers:', error);
        throw error; // Re-throw to handle it in the component
    }
}

export {
    fetchDisciplines,
    fetchDetailedDisciplines,
    fetchQuestions,
    fetchQuestionsWithMedia,
    fetchAllMedia,

    uploadCsv,
    uploadMedia,
    prefixImageUrl,

    fetchBattles,
    fetchBattle,
    fetchBattleLeaderboard,
    createBattle,
    startBattle,
    stopBattle,

    fetchUser,
    fetchUsers,
    downloadUsersCsv,

    fetchPromos,
    uploadPromo,
    claimCode,

    runQualityChecks,

    fetchVouchers,
    createVouchers
};
