<template>
    <div class="card">
        <div class="card-body">
            <form action="#" @submit.prevent="redeem">
                <div class="form-group row">
                    <label for="promocode" class="col-md-4 col-form-label text-md-right">{{ l(this.params.lang,
                'promocode') }}</label>

                    <div class="col-md-6">
                        <input id="promocode" type="text" class="form-control" name="promocode" value required autofocus
                            v-model="promocode" maxlength="6" placeholder="123ABC" />
                    </div>
                </div>

                <div v-if="error" class="alert alert-danger">{{ error }}</div>

                <div class="form-group row mb-0">
                    <div class="col-md-8 offset-md-4">
                        <button type="submit" class="comp-green">{{ l(this.params.lang, 'redeem')}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { claimCode } from '../../repository/apiService.js';
import { l } from '@/repository/l.js';

export default {
    name: "RedeemPromoComponent",
    data() {
        return {
            promocode: '',
            error: null
        };
    },
    computed: {
        params: function () {
            const NL = window.location.search.substring(1) == 'lang=nl';
            return {
                lang: NL ? 'nl' : 'en',
            };
        }
    },
    methods: {
        l: l,
        async redeem() {
            try {
                const response = await claimCode(this.promocode);
                if (response && response.success) {
                    this.error = null;
                    this.$emit('onClaim');
                } else {
                    this.error = response.message;
                }
            } catch (err) {
                this.error = err.message;
            }
        },
    }
};
</script>

<style scoped>
#promocode {
    text-transform: uppercase;
    font-size: 28pt;
}
</style>